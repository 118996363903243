body {
  background-image: linear-gradient(45deg, rgb(146, 199, 243), rgb(72, 155, 223));
  background-color: rgb(146, 199, 243);
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#terrain {
  position: absolute;
  top: 30px;
  left: 320px;
  z-index: 0;
  padding-bottom: 30px;
}

#fog {
  position: absolute;
  top: 30px;
  left: 320px;
  z-index: 1;
  padding-bottom: 30px;
}

#ui {
  font-family: Georgia, sans-serif;
  color: rgb(53, 43, 19);
  background-color: rgb(221, 207, 167);
  width: 200px;
  margin: 30px;
  padding: 30px;
  height: 85vh;
  position: fixed;
}

#player {
  scroll-margin: 240px;
}

.hr {
  border: 1px solid rgb(107, 90, 50);
}

.test {
  color: rgb(0, 255, 221)
}

.statsLettering {
  font-size: 25px;
}

#game-over {
  display: flex; 
  flex-direction: column;
  align-content: center;
  background-color: black;
  color: white;
  min-width: 390px;
  min-height: 210px;
  position: fixed;
  z-index: 2;
  font-size: 25px;
  font-family: Georgia, sans-serif;
  left: 440px;
  top: 40%;
}

#game-won {
  display: flex; 
  flex-direction: column;
  align-content: center;
  background-color: white;
  color: black;
  min-width: 390px;
  min-height: 210px;
  position: fixed;
  z-index: 2;
  font-size: 25px;
  font-family: Georgia, sans-serif;
  left: 440px;
  top: 40%;
}

.button {
  font-size: 20px;
  font-family: Georgia, sans-serif;
  margin-top: 30px;
  padding: 5px;
  background-color: white;
  border: none;
  border-right: solid 2px crimson;
  border-bottom: solid 2px crimson;
}

.button:hover {
  background-color: crimson;
  color: white;
  border-right: solid 2px rgb(83, 0, 0);
  border-bottom: solid 2px rgb(83, 0, 0);
}

.button-won {
  font-size: 20px;
  font-family: Georgia, sans-serif;
  margin-top: 30px;
  padding: 5px;
  color:white;
  background-color: rgb(83, 0, 0);
  border: none;
  border-right: solid 2px crimson;
  border-bottom: solid 2px crimson;
}

.button-won:hover {
  background-color: crimson;
  border-right: solid 2px rgb(83, 0, 0);
  border-bottom: solid 2px rgb(83, 0, 0);
}